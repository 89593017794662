var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ui-input-group"
  }, [_vm.label && !_vm.field ? _c('label', {
    staticClass: "label",
    attrs: {
      "for": `ui-${_vm.uid}`
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _vm.field ? _c('div', {
    staticClass: "top-line"
  }, [_vm.label ? _c('label', {
    staticClass: "label",
    attrs: {
      "for": `ui-${_vm.uid}`
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _c('div', {
    staticClass: "validation-feedback"
  }, [[_c('fade-transition', [_vm.field.show_error && _vm.field.touched ? _c('div', {
    staticClass: "input-error"
  }, [_vm._v(" " + _vm._s(_vm.field.error_text) + " ")]) : _vm._e()]), _vm.field.show_success && _vm.field.touched ? _c('img', {
    staticClass: "input-img img-success",
    attrs: {
      "src": require(`@/assets/images/icons/success.svg`)
    }
  }) : _vm._e(), _vm.field.show_error && _vm.field.touched ? _c('img', {
    staticClass: "input-img img-error",
    attrs: {
      "src": require(`@/assets/images/icons/close.svg`)
    }
  }) : _vm._e()]], 2)]) : _vm._e(), _vm.$attrs.type === 'checkbox' && _vm.component === 'input' ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    ref: "input",
    staticClass: "input",
    class: ['ui-input'],
    attrs: {
      "id": `ui-${_vm.uid}`,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.model) ? _vm._i(_vm.model, null) > -1 : _vm.model
    },
    on: {
      "blur": function ($event) {
        return _vm.$emit('blur');
      },
      "change": function ($event) {
        var $$a = _vm.model,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.model = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.model = $$c;
        }
      }
    }
  }, 'input', _vm.$attrs, false)) : _vm.$attrs.type === 'radio' && _vm.component === 'input' ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    ref: "input",
    staticClass: "input",
    class: ['ui-input'],
    attrs: {
      "id": `ui-${_vm.uid}`,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.model, null)
    },
    on: {
      "blur": function ($event) {
        return _vm.$emit('blur');
      },
      "change": function ($event) {
        _vm.model = null;
      }
    }
  }, 'input', _vm.$attrs, false)) : _vm.component === 'input' ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    ref: "input",
    staticClass: "input",
    class: ['ui-input'],
    attrs: {
      "id": `ui-${_vm.uid}`,
      "type": _vm.$attrs.type
    },
    domProps: {
      "value": _vm.model
    },
    on: {
      "blur": function ($event) {
        return _vm.$emit('blur');
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.model = $event.target.value;
      }
    }
  }, 'input', _vm.$attrs, false)) : _vm.component === 'textarea' ? _c('textarea', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    staticClass: "input",
    class: ['ui-input'],
    attrs: {
      "id": `ui-${_vm.uid}`
    },
    domProps: {
      "value": _vm.model
    },
    on: {
      "blur": function ($event) {
        return _vm.$emit('blur');
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.model = $event.target.value;
      }
    }
  }, 'textarea', _vm.$attrs, false)) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };